/* PriceTable.css */
.search-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.search-button:hover {
  background-color: #0056b3;
}

.price-table-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .price-table-title {
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: #343a40;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .dropdown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .dropdown {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    cursor: pointer;
  }
  
  .price-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .price-table th {
    background-color: #343a40;
    color: white;
    padding: 10px;
    font-size: 16px;
    text-align: left;
  }
  
  .price-table td {
    padding: 10px;
    font-size: 14px;
  }
  
  .even-row {
    background-color: #f2f2f2;
  }
  
  .odd-row {
    background-color: #ffffff;
  }
  
  .price-table tr:hover {
    background-color: #e9ecef;
  }
  
  .error-message {
    color: red;
    font-size: 16px;
    text-align: center;
  }
  