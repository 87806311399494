/* Home.css */
/* Container for the home page */
.home-container {
  /* position: relative; */
  height: 100vh; /* Fullscreen */
  overflow: hidden;
}

/* Fullscreen video background */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire screen */
  z-index: -1; /* Place the video behind all content */
}

/* Content that appears above the video */
.content {
  position: relative;
  z-index: 1; /* Ensure content appears above the video */
  color: white;
  text-align: center;
  top: 50%;
  transform: translateY(-50%); /* Center the content vertically */
}

.content h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: white;
}

.content p {
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
}

/* .home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  } */
  
  .hero-section {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .hero-section h1 {
    font-size: 48px;
    font-weight: bold;
    color: #f7f7f7;
  }
  
  .hero-section p {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 10px;
  }
  
  .info-section {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
  
  .metal-info {
    flex: 1;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .metal-info h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .metal-info p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .learn-more-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-button:hover {
    background-color: #0056b3;
  }
  