
/* Navbar Container */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Title Styling */
  .navbar-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  /* Links Container */
  .navbar-links {
    display: flex;
    gap: 20px;
  }
  
  /* Individual Link Styling */
  .navbar-link {
    text-decoration: none;
    font-size: 18px;
    color: #333;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  /* Hover Effect for Links */
  .navbar-link:hover {
    background-color: #e0e0e0;
  }
  
  /* Navbar end */